
import { Navigate } from "react-router-dom";
function AuthComponent({ children }) {

    return <>{children}</>
  
}

export {
    AuthComponent
}