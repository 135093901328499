import React from 'react';
import styles from './RedButton.module.scss';

interface Props {
  value: string;
  isActive?: boolean;           // 激活状态
  disabled?: boolean;           // 禁用状态
  onClick?: () => void;        // 点击事件
  className?: string;          // 外部传入的类名
  hovertext ?: string;         // 悬停解释框
}

const RedButton: React.FC<Props> = ({ value, isActive = false, disabled = false, onClick, className, hovertext = '' }) => {
  const combinedClassName = `${styles.redButton} ${isActive ? styles.active : ''} ${className || ''}`;

  return (
    <button 
      className={combinedClassName}
      onClick={onClick}
      disabled={disabled}
      data-tooltip = {hovertext}
    >
      {value}
    </button>
  );
}

export default RedButton;



