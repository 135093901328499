import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import { Card,Select, List, Typography } from 'antd';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

import { TimeWidget,CustomTable,BottomStatement } from '@/components';
const Home: React.FC = () => {
  const { Option } = Select;
  const [news, setNews] = useState([]);

    useEffect(() => {
        // Fetch data from your Django backend
        fetch(`${process.env.REACT_APP_API_BASE_URL}/get-news/`) // Adjust the URL based on your setup
          .then(response => {
            return response.json(); // Make sure to return the json() promise
        })
        .then(data => {
            console.log(data.entries); // Log the data here to see it
            setNews(data.entries); // Then set the state
        });
  }, []);
  const [selected, setSelected] = useState<number>(30);

  const [selected2, setSelected2] = useState("Recalls");

  const [selected22, setSelected22] = useState("Month");

  const [selected31, setSelected31] = useState(1);
  const [selected32, setSelected32] = useState(1);
  const [chartData, setChartData] = useState([
    { period: 'January', value: 22 },
    { period: 'February', value: 20 },
    { period: 'March', value: 24 },
    { period: 'April', value: 21 },
    { period: 'May', value: 27 },
    { period: 'June', value: 23 },
    { period: 'July', value: 21 },
    { period: 'August', value: 20 },
    { period: 'September', value: 24 },
    { period: 'October', value: 18 },
    { period: 'November', value: 22 },
    { period: 'December', value: 20 },
  ]);
  const initialCard1Data = [
    { title: 'CLEARANCES', value: 251 },
    { title: 'RECALLS', value: 14 },
    { title: 'DEATHS', value: 1 },
    { title: 'MALFUNCTIONS', value: 183 },
    { title: 'INJURIES', value: 47 }
  ];
  
  // 假设使用useState来保存card1Data状态
  const [card1Data, setCard1Data] = useState(initialCard1Data);
  

  
  const [card31Data, setCard31Data] = useState(
    [
      { Manufacturer: 'Voluntis S.A.', Country: 'France', Number_of_Recalls: '7' },
      { Manufacturer: 'Orthofix, Inc.', Country: 'United States', Number_of_Recalls: '3' },
      { Manufacturer: 'Qualigen, Inc.', Country: 'Turkey', Number_of_Recalls: '8' },
      { Manufacturer: 'GE Healthcare', Country: 'United States', Number_of_Recalls: '3' },
      { Manufacturer: 'Focus Diagnostics', Country: 'United States', Number_of_Recalls: '7' }
    ]
  );
  const [card32Data, setCard32Data] = useState([
    { name: 'Pathology', abbreviation: 'PA', percentage: '42.57%' },
    { name: 'Radiology', abbreviation: 'RA', percentage: '36.13%' },
    { name: 'Orthopedic', abbreviation: 'OR', percentage: '32.72%' },
    { name: 'Cardiovascular', abbreviation: 'CV', percentage: '24.86%' },
    { name: 'Anesthesiology', abbreviation: 'AN', percentage: '19.35%' }
  ]);

  const buttonData = [
    { label: '30D', value: 30 },
    { label: '60D', value: 60 },
    { label: '90D', value: 90 },
    { label: '180D', value: 180 },
    { label: '1Y', value: 365 }
  ];

  const button2Data = [
    'Recalls',
    'Deaths',
    'Malfunctions',
    'Injuries',
  ];

  const button22Data = [
    'Week',
    'Month',
    'Year',
  ];

  const button3Data = [
    1,
    2,
    3,
    4,
    5,
  ];

  
  
  const headers1 = ["Manufacturer", "Country", "Number of Recalls"];
  const headers2 = ["Specialty", "Code", "Avg. Rec. Prob."];

  

  const updateCard1Data = (selectedDays) => {
    // 假设原始数据对应于365天的数据量
    const scaleFactor = selectedDays / 30;
    const newData = initialCard1Data.map(card => ({
      ...card,
      value: Math.round(card.value * scaleFactor) // 使用四舍五入得到整数值
    }));
    setCard1Data(newData);
  };
  
  

  
  const handleButtonClick = (value: number) => {
    setSelected(value);
    updateCard1Data(value);
  };
  

  const handleButtonClick2 = (value: string) => {
    if (value !== selected2) {

      handleButtonClick22(selected22);
      setSelected2(value);
    }

  };

  const handleButtonClick22 = (timeframe: string) => {
    setSelected22(timeframe);
  
    // 基于选中的时间框架生成新的图表数据
    let newChartData;
    switch (timeframe) {
      case 'Week':
        // 创建一个包含周一到周日的数据
        newChartData = [
          { period: 'Monday', value: Math.floor(Math.random() * (100 - 20 + 1)) + 20 },
          { period: 'Tuesday', value: Math.floor(Math.random() * (100 - 20 + 1)) + 20 },
          { period: 'Wednesday', value: Math.floor(Math.random() * (100 - 20 + 1)) + 20 },
          { period: 'Thursday', value: Math.floor(Math.random() * (100 - 20 + 1)) + 20 },
          { period: 'Friday', value: Math.floor(Math.random() * (100 - 20 + 1)) + 20 },
          { period: 'Saturday', value: Math.floor(Math.random() * (100 - 20 + 1)) + 20 },
          { period: 'Sunday', value: Math.floor(Math.random() * (100 - 20 + 1)) + 20 },
        ];
        break;
      case 'Month':
        // 创建一个包含一年十二个月的数据
        newChartData = [
          { period: 'January', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'February', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'March', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'April', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'May', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'June', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'July', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'August', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'September', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'October', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'November', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
          { period: 'December', value: Math.floor(Math.random() * (1000 - 20 + 1)) + 200 },
        ];
        break;
      case 'Year':
        // 创建一个包含十个年份的数据
        newChartData = [];
        const currentYear = new Date().getFullYear();
        for (let i = 0; i < 10; i++) {
          newChartData.push({
            period: `${currentYear - i}`, // 从当前年份开始递减
            value: Math.floor(Math.random() * (10000 - 20 + 1)) + 1000
          });
        }
        // 反转数组以从旧到新排序
        newChartData.reverse();
        break;
      default:
        newChartData = chartData;
    }
  
    // 这里应该有更新图表状态的逻辑
    // setChartData(newChartData); // 假设你有一个名为 setChartData 的状态设置函数
    
    // 调整表格数据
    // 这个操作取决于你如何存储和管理表格数据
    // 假设你的表格数据与时间框架相关联，你可以添加逻辑来调整它们
    // 例如，你可能会增加或减少表格中的数据量，或者更改数据的特定值
    // 这里我使用了随机数据作为演示

    
    setChartData(newChartData);
  };
  


  const adjustProbabilities = (modifier) => {
    // 根据给定的调整系数更新 card31Data 中的概率
    const adjustedData = card31Data.map(item => {
      const newProbability = (parseFloat(item.Number_of_Recalls.replace('%', '')) * modifier).toFixed(2) + '%';
      return { ...item, Number_of_Recalls: newProbability };
    });
    setCard31Data(adjustedData);
  };

  const adjustPercentages = (modifier) => {
    // 根据给定的调整系数更新 card32Data 中的百分比
    const adjustedData = card32Data.map(item => {
      const newPercentage = (parseFloat(item.percentage.replace('%', '')) * modifier).toFixed(2) + '%';
      return { ...item, percentage: newPercentage };
    });
    setCard32Data(adjustedData);
  };

  const handleButtonClick31 = (value) => {
    if (value !== selected31) {
      setSelected31(value);
      // 选择一个基于年数的调整系数
      let modifier;
      switch (value) {
        case 1:
          setCard31Data([
            { Manufacturer: 'Voluntis S.A.', Country: 'France', Number_of_Recalls: '7' },
            { Manufacturer: 'Orthofix, Inc.', Country: 'United States', Number_of_Recalls: '3' },
            { Manufacturer: 'Qualigen, Inc.', Country: 'Turkey', Number_of_Recalls: '8' },
            { Manufacturer: 'GE Healthcare', Country: 'United States', Number_of_Recalls: '3' },
            { Manufacturer: 'Focus Diagnostics', Country: 'United States', Number_of_Recalls: '7' }
          ])
          break;
        case 2:
          setCard31Data([
            { Manufacturer: 'K170669', Country: 'Voluntis S.A.', Number_of_Recalls: '39.36%' },
            { Manufacturer: 'K150962', Country: 'Focus Diagnostics', Number_of_Recalls: '16.56%' },
            { Manufacturer: 'K130825', Country: 'Orthofix, Inc.', Number_of_Recalls: '28.87%' },
            { Manufacturer: 'K182521', Country: 'Qualigen, Inc.', Number_of_Recalls: '49.73%' },
            { Manufacturer: 'K200494', Country: 'GE Healthcare', Number_of_Recalls: '61.51%' }
          ])
          
          break;
        case 3:

          setCard32Data([
            { name: 'Pathology', abbreviation: 'PA', percentage: '22.57%' },
            { name: 'Radiology', abbreviation: 'RA', percentage: '66.13%' },
            { name: 'Orthopedic', abbreviation: 'OR', percentage: '22.72%' },
            { name: 'Cardiovascular', abbreviation: 'CV', percentage: '4.86%' },
            { name: 'Anesthesiology', abbreviation: 'AN', percentage: '14.35%' }
          ])
          break;
        case 4:

          setCard32Data([
            { name: 'Pathology', abbreviation: 'PA', percentage: '4.57%' },
            { name: 'Radiology', abbreviation: 'RA', percentage: '31.13%' },
            { name: 'Orthopedic', abbreviation: 'OR', percentage: '31.72%' },
            { name: 'Cardiovascular', abbreviation: 'CV', percentage: '75.86%' },
            { name: 'Anesthesiology', abbreviation: 'AN', percentage: '1.35%' }
          ])
          break;
        case 5:

          setCard32Data([
            { name: 'Pathology', abbreviation: 'PA', percentage: '22.57%' },
            { name: 'Radiology', abbreviation: 'RA', percentage: '66.13%' },
            { name: 'Orthopedic', abbreviation: 'OR', percentage: '12.72%' },
            { name: 'Cardiovascular', abbreviation: 'CV', percentage: '74.86%' },
            { name: 'Anesthesiology', abbreviation: 'AN', percentage: '9.35%' }
          ])
          break;
        default:
          modifier = card31Data; // 如果没有匹配的值，默认不做调整
      }
    }
  };
  
  const handleButtonClick32 = (value) => {
    if (value !== selected32) {
      setSelected31(value);
      // 选择一个基于年数的调整系数
      let modifier;
      switch (value) {
        case 1:
          setCard31Data([
            { Manufacturer: 'Voluntis S.A.', Country: 'Voluntis S.A.', Number_of_Recalls: '7' },
            { Manufacturer: 'Orthofix, Inc.', Country: 'Focus Diagnostics', Number_of_Recalls: '3' },
            { Manufacturer: 'Qualigen, Inc.', Country: 'Orthofix, Inc.', Number_of_Recalls: '8' },
            { Manufacturer: 'GE Healthcare', Country: 'Qualigen, Inc.', Number_of_Recalls: '3' },
            { Manufacturer: 'Focus Diagnostics', Country: 'GE Healthcare', Number_of_Recalls: '7' }
          ])
          
          break;
        case 2:
          setCard31Data([
            { Manufacturer: 'K170669', Country: 'Voluntis S.A.', Number_of_Recalls: '39.36%' },
            { Manufacturer: 'K150962', Country: 'Focus Diagnostics', Number_of_Recalls: '16.56%' },
            { Manufacturer: 'K130825', Country: 'Orthofix, Inc.', Number_of_Recalls: '28.87%' },
            { Manufacturer: 'K182521', Country: 'Qualigen, Inc.', Number_of_Recalls: '49.73%' },
            { Manufacturer: 'K200494', Country: 'GE Healthcare', Number_of_Recalls: '61.51%' }
          ])
          
          break;
        case 3:
          setCard31Data([
            { Manufacturer: 'K170669', Country: 'Voluntis S.A.', Number_of_Recalls: '31.36%' },
            { Manufacturer: 'K150962', Country: 'Focus Diagnostics', Number_of_Recalls: '55.56%' },
            { Manufacturer: 'K130825', Country: 'Orthofix, Inc.', Number_of_Recalls: '29.87%' },
            { Manufacturer: 'K182521', Country: 'Qualigen, Inc.', Number_of_Recalls: '11.73%' },
            { Manufacturer: 'K200494', Country: 'GE Healthcare', Number_of_Recalls: '48.51%' }
          ])
          
          break;
        case 4:
          setCard31Data([
            { Manufacturer: 'K170669', Country: 'Voluntis S.A.', Number_of_Recalls: '11.36%' },
            { Manufacturer: 'K150962', Country: 'Focus Diagnostics', Number_of_Recalls: '74.56%' },
            { Manufacturer: 'K130825', Country: 'Orthofix, Inc.', Number_of_Recalls: '52.87%' },
            { Manufacturer: 'K182521', Country: 'Qualigen, Inc.', Number_of_Recalls: '26.73%' },
            { Manufacturer: 'K200494', Country: 'GE Healthcare', Number_of_Recalls: '62.51%' }
          ])
          
          break;
        case 5:
          setCard31Data([
            { Manufacturer: 'K170669', Country: 'Voluntis S.A.', Number_of_Recalls: '26.36%' },
            { Manufacturer: 'K150962', Country: 'Focus Diagnostics', Number_of_Recalls: '41.56%' },
            { Manufacturer: 'K130825', Country: 'Orthofix, Inc.', Number_of_Recalls: '53.87%' },
            { Manufacturer: 'K182521', Country: 'Qualigen, Inc.', Number_of_Recalls: '46.73%' },
            { Manufacturer: 'K200494', Country: 'GE Healthcare', Number_of_Recalls: '26.51%' }
          ])
          
          break;
        default:
          modifier = card31Data; // 如果没有匹配的值，默认不做调整
      }
    }
  };

  return (
    <div>
      <div className={styles.contentContainer}>
        
        <div className={styles.firstRow}>
          <div className={styles.timeWidgetContainer}>
              <TimeWidget />
          </div>
        
        </div>
        <div className={styles.CardsContainer2}>

            <Card title = 
              <p className={styles.Cards2Title}> The Lastest Medical Device Recall Reports from the FDA </p> 
              className={styles.Cards2}  bordered={false} >
              <List
                  style={{ maxHeight: '400px', overflow: 'auto' }}
                  bordered
                  dataSource={news}
                  renderItem={item => (
                      <List.Item>
                          <Typography.Text mark>[{item.date}]</Typography.Text> <a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a>
                      </List.Item>
                  )}
              />
            </Card>
        </div>
        <p className={styles.TopTitle}>Number of Adverse Events</p>
        <div className={styles.buttonGroup1}>
            
            {buttonData.map(btn => (
              <button
                key={btn.value}
                className={`${styles.btn1} ${selected === btn.value ? styles.active : ''}`}
                onClick={() => handleButtonClick(btn.value)}
              >
                {btn.label}
              </button>
            ))}
          </div>
        <div className={styles.CardsContainer1}>
          {card1Data.map((card) => (
            <Card className={styles.Cards1} key={card.title} bordered={false} >
              <div className={`${styles.cardContent}`}>
                <div className={styles.cardTitle}>{card.title}</div>
                <div className={styles.cardValue}>{card.value}</div>
              </div>
            </Card>
          ))}
        </div>
        
        <div className={styles.CardsContainer2}>
          <Card 
            className={styles.Cards2}>
            <div className={styles.buttonRedGroup}>
              {button2Data.map(btn => (
                <button
                  className={`${styles.btnRed} ${selected2 === btn? styles.activeRed : ''}`}
                  onClick={() => handleButtonClick2(btn)}
                >
                  {btn}
                </button>
              ))}
            </div>
            <div className={styles.chartContainer}>
              <Column
                className={styles.chartColumn}
                data={chartData}
                xField="period"
                yField="value"
                columnStyle={{
                  radius: [8, 8, 8, 8],  // 上右、上左、下右、下左的边角半径
                }}
                label={{
                  position: 'top', // 标签位置
                  offset: 0, // 与柱子顶部的距离
                  style: {
                    fill: '#000000',
                    fontSize: 14,
                    fontWeight: 'bold',
                  },
                }}
                xAxis={{
                  label: {
                    style: {
                      fill: '#000000',
                      fontSize: 12,
                      fontWeight: 'bold',
                    },
                  },
                }}
                columnWidthRatio={0.6}
                color="#6F131D"
        
              />
            </div>  
              
            <div className={styles.buttonRedGroup}>
              {button22Data.map(btn => (
                <button
                  key={btn}
                  className={`${styles.btnRed} ${selected22 === btn ? styles.activeRed : ''}`}
                  onClick={() => handleButtonClick22(btn)}
                >
                  {btn}
                </button>
              ))}
            </div>

          </Card>
          
        </div>
        <div className={styles.CardsContainer1}>
          <Card className={styles.Cards3}>
            <p className={styles.Cards3Title}> Top 5 Manufacturer with Largest Number of Recalls </p>
            <CustomTable headers={headers1} data={card31Data} sortBy='Number_of_Recalls'/>
            <div className={styles.ChoiceContainer3}>
              Past  &nbsp;&nbsp;
              <Select value={selected31} onChange={handleButtonClick31}>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
              </Select>
              &nbsp;&nbsp;  year{selected31 > 1 ? 's' : ''}
            </div>
          </Card>
          <Card className={styles.Cards3}>
            <p className={styles.Cards3Title}> Top 5 Medical Specialties with Highest Predicted Recall Probabilities </p>
            <CustomTable headers={headers2} data={card32Data} sortBy='percentage'/>
            <div className={styles.ChoiceContainer3}>
              Fulture  &nbsp;&nbsp;
              <Select value={selected32} onChange={handleButtonClick32}>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
              </Select>
              &nbsp;&nbsp;  year{selected32 > 1 ? 's' : ''}
            </div>
          </Card>
        </div>
      </div>
      <BottomStatement />
    </div>
    
  );
};

export default Home;



