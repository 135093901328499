import React, { FC } from 'react';
import { Layout as AntdLayout, Menu, Breadcrumb  } from 'antd';
import { Outlet, Link, useLocation, useParams} from 'react-router-dom';

import styles from './index.module.scss';
import TimeWidget from '@/components/TimeWidget';
import { BottomStatement } from '@/components';
const { Sider, Content } = AntdLayout;

interface SidebarLayoutProps {
  children: React.ReactNode;
}

const SidebarLayout: React.FC = () => {
  const { section } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryValue = queryParams.get('query');
  const currentQueryParams = queryValue ? `?query=${queryValue}` : '';
  

  const menuItems = [
    { key: '1', label: 'Basic Information', link: '/knumber/Basic-Information' + currentQueryParams },
    { key: '2', label: 'Predicate Device', link: '/knumber/Predicate-Device' + currentQueryParams },
    { key: '3', label: 'Recall Prediction', link: '/knumber/recall-prediction' + currentQueryParams },
    { key: '4', label: 'Recall Probability Comparison', link: '/knumber/recall-comparison' + currentQueryParams },
    { key: '5', label: 'Customized Predictive Model', link: '/knumber/custom-model' + currentQueryParams },
  ];
  const currentSection = location.pathname.split("/")[2];
  const currentItem = menuItems.find(item => item.link.includes(currentSection));
  const currentLabel = currentItem ? currentItem.label : 'Unknown';
  const getCurrentMenuKey = () => {
    const currentPath = location.pathname;
    const matchedItem = menuItems.find(item => currentPath.startsWith(item.link.split('?')[0]));
    return matchedItem ? matchedItem.key : '1'; // default to '1' if not matched
  }
  
    
  return (
    <AntdLayout className={styles.layout}>
        <Sider width={305} className={styles.siderContainer}>
        <div className={styles.internalContainer}>
          
        
        <Link to="/" className={styles.linkContainer}>
          &larr; Return to Dashboard
        </Link>
        <Menu mode="vertical" selectedKeys={[getCurrentMenuKey()]} className={styles.menuContainer}>

              {menuItems.map(item => (
                  <Menu.Item key={item.key} className={styles.menuItem}>
                      <Link to={item.link}>{item.label}</Link>
                  </Menu.Item>
              ))}
          </Menu>
          <div className={styles.timeWidgetContainer}>
              <TimeWidget />
          </div>
          </div>
        </Sider>
      
        <AntdLayout  >
          <div className={styles.layoutContent}>
            <Breadcrumb>
                <Breadcrumb.Item className={styles.breadcrumbText}>{queryValue}</Breadcrumb.Item>
                <Breadcrumb.Item className={styles.breadcrumbText}>{currentLabel}</Breadcrumb.Item>
            </Breadcrumb>
            <Outlet />
          </div>
            
            <BottomStatement />
        </AntdLayout>
        
    </AntdLayout>
  );
}

export default SidebarLayout;
