import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, Card, Dropdown, Menu,MenuProps, Layout } from 'antd';
import styles from './index.module.scss';
import { observer } from 'mobx-react'; 
import { RedButton, NodeMap } from '@/components';
interface KnumberProps {
    // 你可以在这里定义任何你需要的props
}

const PredicateDevice: React.FC<KnumberProps> = (props) => {
    const [results, setResults] = useState<any[]>([]); // 存储搜索结果
    const location = useLocation();
    const [selected3, setSelected3] = useState("Month");
    const { Content } = Layout;
    const [isContainerReady, setContainerReady] = useState(false);
    useEffect(() => {
      // 在组件渲染到DOM后，设置状态以表明容器已准备好
      setContainerReady(true);
    }, []);

    useEffect(() => {
        // 获取查询参数
        const params = new URLSearchParams(location.search);
        const query = params.get('query');

        if (query) {
            // 假设您有一个API来获取搜索结果
            fetch(`/api/search?query=${query}`)
                .then(res => res.json())
                .then(data => {
                    setResults(data);
                })
                .catch(error => {
                    console.error("Error fetching search results:", error);
                });
        }
    }, [location.search]);

    const items: MenuProps['items'] = [
      {
        key: '1',
        label: (
          <div className={styles.inDropContainer}>
            <RedButton className={styles.inDropButton} hovertext="more detail" value= {"K000002"}/>
          </div>
          
        ),
      },
      {
        key: '2',
        label: (
          <RedButton className={styles.inDropButton} hovertext="more detail" value= {"K000002"}/>
        ),
      },
      {
        key: '3',
        label: (
          <RedButton className={styles.inDropButton} hovertext="more detail" value= {"K000002"}/>
        ),
      },
    ];

    const boxesData = [
      { id: 'box1', label: 'A' },
      { id: 'box2', label: 'B' },
      { id: 'box3', label: 'C' },
      // ... 可以继续添加更多数据
    ];
    
    const connectorsData = [
      { from: 'box1', to: 'box2' },
      { from: 'box2', to: 'box3' },
      // ... 可以继续添加更多连接数据
    ];
    

    return (
        <div className={styles.contentContainer}>
          <div className={styles.leftContentContainer}>
            <div className={styles.leftContentContainer2}>
              <p className={styles.header}> Predicates (Predecessors) </p>
              <Content className={styles.leftContentCard} style={{ overflow: 'auto', padding: 24 }}>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
              </Content>

            </div>
            <div className={styles.leftContentContainer2}>
              <p className={styles.header}> Cited by (Successors) </p>
              <Content className={styles.leftContentCard} style={{ overflow: 'auto', padding: 24 }}>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
                <RedButton className={styles.hoverButton} hovertext="more detail" value= {"K000002"}/>
              </Content>
            </div>
           
            
          </div>

          <div className={styles.rightContentContainer}>
            <p className={styles.header}> Predicate Network </p>
            {isContainerReady && <NodeMap/>}
          </div>  
        </div>
    );
}

export default observer(PredicateDevice);
