import React from 'react';
import styles from './CustomTable.module.scss';
interface IProps {
  headers: string[];
  data: {
    [key: string]: any;
  }[];
  sortBy?: string;
}

const CustomTable: React.FC<IProps> = ({ headers, data, sortBy }) => {
  const findValueByCaseInsensitiveKey = (obj: { [key: string]: any }, key: string): any => {
    const foundKey = Object.keys(obj).find(k => k.toLowerCase() === key.toLowerCase());
    return foundKey ? obj[foundKey] : null;
  };
  const sortData = (data: any[]) => {
    if (!sortBy) return data;
    
    return [...data].sort((a, b) => {
      const aValue = findValueByCaseInsensitiveKey(a, sortBy);
      const bValue = findValueByCaseInsensitiveKey(b, sortBy);
      console.log(data)
      console.log('aValue',aValue);
      // 检查值是否存在，如果不存在，则视为0（或其他适当的默认值）
      const numericAValue = aValue ? parseFloat(aValue.replace('%', '')) : 0;
      const numericBValue = bValue ? parseFloat(bValue.replace('%', '')) : 0;
  
      return numericBValue - numericAValue;
    });
  };
  

  const sortedData = sortData(data);
  return (
    <table className={styles.customTable}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {Object.values(row).map((value, cellIndex) => (
              <td key={cellIndex}>{value != null ? value.toString() : ''}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CustomTable;

