import React, { FC, useState } from 'react';
import { Input, Button, Layout as AntdLayout, message } from 'antd';
import { SearchOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Outlet, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

const { Header, Content, Footer } = AntdLayout;

const Layout: FC = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleLogoClick = () => {
    navigate('/'); // 跳转到主页
  };
  
  const handleSearch = async (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault(); // Prevent the default form submission behavior
    
    if (searchValue) { // Ensure the search value exists
      // Adjust the URL to the new endpoint for checking product existence
      const url = `${process.env.REACT_APP_API_BASE_URL}/check_kid?kid=${encodeURIComponent(searchValue)}`;

      try {
        // Make the HTTP GET request to the backend endpoint
        const response = await fetch(url);
        
        if (!response.ok) {
          // If the response is not 2xx, handle it as an error
          throw new Error('Network response was not ok');
        }
  
        // Assuming your backend returns JSON indicating the existence of the product
        const { exists } = await response.json();
        
        if (exists) {
          // If the product exists, navigate to the specified route
          navigate(`/knumber/Basic-Information?query=${encodeURIComponent(searchValue)}`);
        } else {
          // If the product does not exist, show an error message or handle accordingly
          messageApi.open({
            type: 'error',
            content: 'No product found with the provided KID',
          });
        }
        
      } catch (error) {
        // Handle any errors that occurred during the fetch
        console.error('There was a problem with your fetch operation:', error);
        
        // Show a message to the user, for example
        messageApi.open({
          type: 'error',
          content: 'Failed to fetch product existence information',
        });
      }
    } else {
      messageApi.open({
        type: 'warning',
        content: 'Please enter a valid value',
      });
    }
  };



  const goContribute = () => {
    navigate('/contribute');
    console.log("goContribute");
  };


  return (
    <AntdLayout className={styles.layoutContent}>
        {contextHolder}
        <Header className={styles.headerContainer}>
            {/* Logo */}
            <div className={styles.logo} onClick={handleLogoClick}>
              MEDEVISOR
            </div>

            {/* Search Input */}
            <form onSubmit={handleSearch} className={`${styles.inputContainer} layoutInputContainer`}>
              <Input
                value={searchValue} // 绑定值
                onChange={handleInputChange} // 绑定输入变更事件处理器
                className={styles.inputStyle}
                placeholder="Insert a K-Number"
                prefix={<SearchOutlined className={styles.iconStyle} />}
              />
              <Button 
                type="primary"
                icon={<ArrowRightOutlined />}
                htmlType="submit" // 使按钮成为提交按钮
                className={styles.searchButton}
              />
            </form>

            {/* Contribute Data Button */}
            <Button type="primary" className={styles.roundedButton} onClick={() =>goContribute()}>Contribute Data</Button>
        </Header>
        <Content >
            <Outlet />
        </Content>
    </AntdLayout>
  );
}

export default Layout;


