import React, { useState, useEffect }from 'react';
import styles from './index.module.scss';
import { Slider } from 'antd';
import { Column } from '@ant-design/plots';
import { Scatter } from '@antv/g2plot';
interface DataPoint {
    x: number;
    y: number;
    label?: string;  // label属性是可选的
  }
  
const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`; // 返回RGB颜色字符串
  };

const getMedian = (arr) => {
const sortedArr = [...arr].sort((a, b) => a - b);
const midIndex = Math.floor(sortedArr.length / 2);
return sortedArr.length % 2 !== 0
    ? sortedArr[midIndex]
    : (sortedArr[midIndex - 1] + sortedArr[midIndex]) / 2;
};

const RecallComparison: React.FC = () => {
    const [selected, setSelected] = useState('Same product code');
    const [yearRange, setYearRange] = useState([1999, 2015]);
    const [colorMap, setColorMap] = useState({});
    const [scatterData, setScatterData] = useState<DataPoint[]>([
        { x: 0.1, y: 0,label: 'K000001' },
        // ... 其他数据点
        { x: 0.74, y: 0},
        { x: 0.54, y: 0 },
        { x: 0.13, y: 0 },
        { x: 0.9, y: 0 },
    ]);

    
    const handleButtonClick = (btn) => {
        // Only update if a different button was clicked
        if (btn !== selected) {
          setSelected(btn);
          onSliderChange();
        }
    }

    useEffect(() => {
        // 提取出所有的x值
        const xValues = scatterData.map(point => point.x);
        console.log(xValues);
        // 计算中位数
        const medianValue = getMedian(xValues);
        console.log(medianValue);
        // 构建新的数据数组，包括中位数点
        const newData: DataPoint[] = [
          ...scatterData,
          { x: medianValue, y: 0, label: 'median' }, // 添加中位数点
        ];
        setScatterData(newData);
      }, []);

    useEffect(() => {
        // 初始化颜色映射
        const newColorMap = {};
        scatterData.forEach((data) => {
          if (data.label && !newColorMap[data.label]) {
            newColorMap[data.label] = generateRandomColor();
          }
        });
        setColorMap(newColorMap);
      }, [scatterData]);

    useEffect(() => {
        const scatterPlot = new Scatter(document.getElementById('scatterPlot'), {
            appendPadding: 30,
            data: scatterData,
            size: 10,
            legend: false,
            xField: 'x',
            yField: 'y',
            xAxis: {
                min: 0,
                max: 1,

                tickLine: null,
                label: null,
                title: null,
            },
            yAxis: false,
            colorField: 'label',
            color: (data) => data.label ? colorMap[data.label.toString()] : '#5B8FF9',
            pointStyle: (data) => {
                if (data.label) {
                  // 有label的数据点
                  return {
                    fill: colorMap[data.label.toString()], // 实心颜色
                    fillOpacity: 1, // 不透明
                    stroke: 'none', // 边框颜色
                    lineWidth: 0, // 设置线宽为0，去除边框
                  };
                }
                // 无label的数据点保持默认样式
                return { fillOpacity: 0.5, stroke: '#5B8FF9' };
            },
            label: {
                // 显示标签的配置
                position: 'top', // 标签位置
                offsetY: -15, // Y轴偏移量
                formatter: (datum) => {
                    // 格式化标签
                    return datum.label ? `${datum.label} \n(${(datum.x * 100).toFixed(2)}%)` : '';
                },
            },
            tooltip: {
                showTitle: false, // 不显示默认标题
                formatter: (data) => {
                    // 只显示 x 值和 label（如果有的话）
                    if (data.label) {
                        return { name: data.label, value: `${(data.x * 100).toFixed(2)}%` };
                    } else {
                        return { name: 'Probability', value: `${(data.x * 100).toFixed(2)}%` };
                    }
                },
            },
            // 添加辅助线
            annotations: [
                
                {
                    type: 'text',
                    position: ['min', 0],
                    content: '0%', // 文本内容
                    style: {
                        textAlign: 'start', // 文本对齐方式
                    },
                    offsetX: -10,
                    offsetY: 10, // 在Y轴方向上的偏移量
                },
                {
                    type: 'text',
                    position: ['max', 0],
                    content: '100%', // 文本内容
                    style: {
                        textAlign: 'end', // 文本对齐方式
                    },
                    offsetX: 20,
                    offsetY: 10, // 在Y轴方向上的偏移量
                },
            ],
        });

        scatterPlot.render();

        // 组件卸载时销毁图表
        return () => {
            scatterPlot.destroy();
        };
    }, [scatterData, colorMap]); // 依赖项数组中的数据变化时，重新渲染图表

    const onSliderChange = () => {
        // 生成新的散点数据
        const newData = scatterData.map((data) => {
            // 如果 label 是 "median"，保持数据不变
            if (data.label === "median") {
              return data;
            }
        
            // 否则，更新 x 值和 label 的最后一位
            return {
              ...data,
              x: Math.round(Math.random() * 100) / 100, // 生成一个 0 到 1 之间的两位小数
              label: data.label ? data.label.slice(0, -1) + Math.floor(Math.random() * 10) : undefined // 更改 label 的最后一位
            };
          });
      
        setScatterData(newData);
      };
    const buttonData = [
        'All devices',
        'Same category',
        'Same product code',
    ];

    return (
        <div className={styles.contentContainer}>
            <div className={styles.cardContainer}>
                <div className={styles.graphContainer}>
                    <p className={styles.CardsTitle}> Device Information </p> 
                    <div id="scatterPlot" className={styles.chart} style={{ height: '200px' }}></div>
                </div>
                <div className={styles.sliderContainer}>
                   <Slider
                        range={{ draggableTrack: true }}
                        min={1977}
                        max={2023}
                        step={1}
                        defaultValue={yearRange}
                        tooltip={{ open: true }}
                        onChange={onSliderChange}
                    /> 
                </div>
                
            </div>
            <div className={styles.buttonRedGroup}>
                {buttonData.map(btn => (
                <button
                    className={`${styles.btnRed} ${selected === btn? styles.activeRed : ''}`}
                    onClick={() => handleButtonClick(btn)}
                >
                    {btn}
                </button>
                ))}
            </div>
        </div>
    );
}

export default RecallComparison;

