// BottomStatement.tsx
import React from 'react';
import styles from './index.module.scss'; 

const BottomStatement: React.FC = () => {
  return (
    <div className={styles.bottomStatement}>
      <div>Disclaimer: This is a prototype of the Medevisor decision support system.</div>
      <div>Information presented on this prototype is not based on real data and does not provide any clinical recommendations.</div>
    </div>
  );
};

export default BottomStatement;
