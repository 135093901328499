import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb, Card, Row, Col, Skeleton } from 'antd';
import styles from './index.module.scss';
import { RedButton,CustomTable } from '@/components';
interface KnumberProps {
    // 你可以在这里定义任何你需要的props
}

const BasicInfo: React.FC<KnumberProps> = (props) => {
    const [results, setResults] = useState<any[]>([]); // 存储搜索结果
    const location = useLocation();
    const [data1, setData1] = useState([]);
    const [selected3, setSelected3] = useState("Month");
    const [card3Data, setCard3Data] = useState([
      { title: 'Deaths', value: 0 },
      { title: 'Malfunctions', value: 14 },
      { title: 'Injuries', value: 1 },
      { title: 'Others', value: 183 },
    ]);
    const [loading, setLoading] = useState(true);

      
    useEffect(() => {
      const query = new URLSearchParams(location.search).get('query');
  
      if (query) {
          const url = `${process.env.REACT_APP_API_BASE_URL}/device-info/?query=${encodeURIComponent(query)}`;
  
          fetch(url)
              .then(res => res.json())
              .then(data => {
                  // 假设后端返回的数据结构与data1的期望结构相匹配
                  const newData1 = [
                      { title: 'DEVICE NAME', value: data.name },
                      { title: 'RECEIVED DATE', value: data.decision?.received_date },
                      { title: 'DECISION DATE', value: data.decision?.decision_date },
                      { title: 'MANUFACTURER', value: data.manufacturer?.name },
                      { title: 'MANUFACTURER ADDRESS', value: data.manufacturer?.address }, // 需要根据实际返回的数据结构调整
                      { title: 'CORRESPONDENT CONTACT', value: data.manufacturer?.contact },
                      { title: 'DEVICE CLASSIFICATION TOOL', value: data.product_code },
                      { title: 'MEDICAL SPECIALTY', value: data.specialty }
                  ];
                  setData1(newData1); // 确保你有一个名为setData1的状态更新函数
                  setLoading(false);
              })
              .catch(error => console.error("Error fetching device info:", error));
      }
    }, [location.search]);
  
    
      const button32Data = [
        'Week',
        'Month',
        'Year',
      ];
      const headers1 = ["Recall Date", "Resolving Date", "Recall Type"];
      const card21Data = [
        { kNumber: '12/8/15', manufacturer: 'Unresolved', probability: 'I' },
        { kNumber: '9/26/14', manufacturer: '1/2/15', probability: 'II' },
        { kNumber: '4/18/12', manufacturer: '3/19/13', probability: 'II' },
      ];
      const handleButtonClick3 = (btn) => {
        // Only update if a different button was clicked
        if (btn !== selected3) {
          setSelected3(btn);
    
          // Define the values to add or subtract for each button
          const valueChanges = {
            Week: { Deaths: 1, Malfunctions: 2, Injuries: 1, Others: 5 },
            Month: { Deaths: 0, Malfunctions: 14, Injuries: 1, Others: 183 },
            Year: { Deaths: -1, Malfunctions: -2, Injuries: -1, Others: -5 }
          };
    
          // Update the card3Data state based on the button clicked
          setCard3Data(card3Data.map(card => ({
            ...card,
            value: card.value + (valueChanges[btn][card.title] || 0)
          })));
        }
      };
    useEffect(() => {
        // 获取查询参数
        const params = new URLSearchParams(location.search);
        const query = params.get('query');

        if (query) {
            // 假设您有一个API来获取搜索结果
            fetch(`/api/search?query=${query}`)
                .then(res => res.json())
                .then(data => {
                    setResults(data);
                })
                .catch(error => {
                    console.error("Error fetching search results:", error);
                });
        }
    }, [location.search]);

    return (
        <div className={styles.contentContainer}>
            
            <div className={styles.CardsContainer1}>
              <Card className={styles.Cards} title={<p className={styles.Cards1Title}> Device Information </p>}>
                  <div className={styles.card1Content}>
                      {loading ? (
                          <Skeleton active />
                      ) : (
                          data1.map((item, index) => (
                              <Row key={index} className={styles.card1Subcontainer}>
                                  <Col className={styles.card1Subtitle}>{item.title}</Col>
                                  <Col className={styles.card1text}>{item.value}</Col>
                              </Row>
                          ))
                      )}
                  </div>
              </Card>
          </div>


            <div className={styles.CardsContainer2}>
                <Card className={styles.Cards} 
                >
                    <div className={styles.card2Subcontainer}>
                        <div className={styles.card21Subcontainer}>
                            <p className={styles.Cards2Title}> Current Status </p> 
                            <div className={styles.statusText}>
                              Recalled
                            </div>
                           
                        </div>
                        <div className={styles.card22Subcontainer}>
                            <p className={styles.Cards2Title}> Years in the Market </p> 
                            <div className={styles.numberDisplay}>
                              4
                            </div>
                        </div>
                        <div className={styles.card22Subcontainer}>
                            <p className={styles.Cards2Title}> Recall History </p> 
                            <CustomTable headers={headers1} data={card21Data} />
                        </div>  
                    </div>
                    
                </Card>
            </div>

            <p className={styles.Cards1Title}> Adverse Events </p> 

            <div className={styles.CardsContainer3}>
                {card3Data.map((card) => (
                <Card className={styles.Cards1} key={card.title} bordered={false} >
                    <div className={`${styles.cardContent}`}>
                    <div className={styles.cardTitle}>{card.title}</div>
                    <div className={styles.cardValue}>{card.value}</div>
                    </div>
                </Card>
                ))}
            </div>

            <div className={styles.buttonRedGroup}>
                {button32Data.map(btn => (
                <button
                    className={`${styles.btnRed} ${selected3 === btn? styles.activeRed : ''}`}
                    onClick={() => handleButtonClick3(btn)}
                >
                    {btn}
                </button>
                ))}
            </div>
        </div>
    );
}

export default BasicInfo;
