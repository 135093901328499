import React from 'react';

import {Home, Contribute} from "@/pages";

import './App.css';
import { Routes, Route } from "react-router-dom";
import { AuthComponent } from '@/components/AuthComponent';
import Layout from '@/pages/Layout';
import SidebarLayout from '@/pages/SidebarLayout';
import {BasicInfo, PredicateDevice, RecallPrediction, RecallComparison, CustomModel} from "@/pages/KNumber";
function App() {
  return (

    <Routes>
    <Route path="/" element={
      <AuthComponent>
        <Layout />
      </AuthComponent>
    }>
      <Route index element={<Home />} />
      <Route path="/contribute" element={<Contribute />} />
      <Route path="/knumber" element={<SidebarLayout />}>
        <Route path="Basic-Information" element={<BasicInfo />} />
        <Route path="Predicate-Device" element={<PredicateDevice />} />
        <Route path="Recall-Prediction" element={<RecallPrediction />} />
        <Route path="Recall-Comparison" element={<RecallComparison />} />
        <Route path="custom-model" element={<CustomModel />} />
      </Route>
    </Route>
  </Routes>

  );
}

export default App;
