import React, { useState, useEffect } from 'react';

import { Line } from '@ant-design/plots';

const LineChart = () => {
  const data = [
    {
      year: '1',
      value: 0.22,
    },
    {
      year: '2',
      value: 0.24,
    },
    {
      year: '3',
      value: 0.35,
    },
    {
      year: '4',
      value: 0.4,
    },
    {
      year: '5',
      value: 0.46,
    },
  ];
  const config = {
    data,
    xField: 'year',
    yField: 'value',
    height: 200,
    label: {
      formatter: (item) => `${(item.value * 100).toFixed(1)}%`, // 为标签文本格式化函数
    },
    point: {
      size: 5,
      shape: 'circle',
      style: {
        fill: 'white',
        stroke: '#136F56',
        lineWidth: 2,
      },
    },
    xAxis:{
      label: {
        // 设置 y 轴标签的格式化器
        formatter: (val) => `Within ${val} Year`,
      },
    },
    yAxis:{
      label: {
        // 设置 y 轴标签的格式化器
        formatter: (val) => `${(val * 100)}%`,
      },
    },
    lineStyle: {
      stroke: '#136F56', // 设置线的颜色
      lineWidth: 2,      // 你可以设置线的宽度
    },
    tooltip: {
      showMarkers: false,
      showTitle: false,
      formatter: (datum) => {
        
        return {
          name: `Within ${datum.year} Year`,
          value: `${(datum.value * 100).toFixed(1)}%`
        };
      }
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    scale: {
      value: {
        formatter: (value) => `${(value * 100).toFixed(2)}%`, // 将数值转换为百分比
      },
    },
  };
  return <Line {...config} />;
};

export default LineChart;