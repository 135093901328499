import React, { useState, useEffect } from 'react';
import styles from './TimeWidget.module.scss';

const TimeWidget: React.FC = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const formatTime = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={styles.container}>
      {formatTime(currentDate)}
    </div>
  );
}

export default TimeWidget;

