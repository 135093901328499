import React, { useState } from 'react';
import styles from './index.module.scss';
import { Input, Select, Upload, Button, Form } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { Outlet, useNavigate } from 'react-router-dom';
import { TimeWidget, CustomTable, BottomStatement } from '@/components';
const { TextArea } = Input;

const Contribute: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const [dataInformation, setDataInformation] = useState('');

  const returnHome = () => {
    navigate('/'); // 跳转到主页
  };

  const beforeUpload = (file) => {
    // 添加新文件到当前文件列表
    setFileList((currentFileList) => [...currentFileList, file]);
    // 阻止默认上传行为
    return false;
  };

  const handleSubmit = (values) => {
    // 处理提交逻辑
    console.log('Form Values:', values);
  };

  return (
    <div className={styles.contentContainer}>
      <Form form={form} onFinish={handleSubmit}>
        <div className={styles.card}>
          <div className={styles.headerContainer}>
            <button className={styles.returnButton} onClick={returnHome}>
              Back
            </button>
            <p className={styles.header}>Thanks for your Contribution</p>
          </div>
          <div className={styles.formContainer}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input className = {styles.littleComponent} placeholder="Name" />
            </Form.Item>

            <Form.Item
              label="Company"
              name="company"
              rules={[{ required: true, message: 'Please input your company!' }]}
            >
              <Input className = {styles.littleComponent} placeholder="Company" />
            </Form.Item>

            <Form.Item
              label="Position"
              name="position"
              rules={[{ required: true, message: 'Please input your position!' }]}
            >
              <Input className = {styles.littleComponent} placeholder="Position" />
            </Form.Item>

            <Form.Item
              label="Data Information"
              name="dataInformation"
              rules={[{ required: true, message: 'Please select data information!' }]}
            >
              <Select
                className = {styles.littleComponent}
                placeholder="Data information"
                onChange={(value) => setDataInformation(value)}
              >
                <Select.Option value="clinical tests">Clinical Tests</Select.Option>
                <Select.Option value="customer feedback">Customer Feedback</Select.Option>
                <Select.Option value="failure records">Failure Records</Select.Option>
                <Select.Option value="other">Other</Select.Option>
              </Select>
            </Form.Item>

            {dataInformation === 'other' && (
              <Form.Item label="Other Information" name="otherInformation">
                <Input 
                className = {styles.littleComponent}
                placeholder="Please specify" />
              </Form.Item>
            )}

            <Form.Item label="Upload">
              <Upload.Dragger
                name="audio"
                listType="picture-card"
                className={styles.uploader}
                showUploadList={{
                  showPreviewIcon: true,
                  showRemoveIcon: true,
                  showDownloadIcon: false,
                }}
                fileList={fileList}
                beforeUpload={beforeUpload}
                onRemove={(file) => {
                  setFileList((oldAudioList) => oldAudioList.filter((item) => item.uid !== file.uid));
                }}
                onPreview={() => {}}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
              </Upload.Dragger>
            </Form.Item>

            <Form.Item label="Additional Information" name="additionalInfo">
              <TextArea placeholder="additional information" autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>


            <Form.Item>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Contribute;



