import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Progress, Select, message } from 'antd';
import styles from './index.module.scss';
import { observer } from 'mobx-react'; 
import  DragMap  from '@/components/DragMap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { set } from 'mobx';
interface KnumberProps {
    // 你可以在这里定义任何你需要的props
}

const DraggableContainer = ({ items, droppableId, className }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={styles.itemContainer}
        >
          {items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}
            style={{ overflow: 'auto', padding: 24 }}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={className} // 使用传入的 className
                >
                  {item.content}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};


const CustomModel: React.FC<KnumberProps> = (props) => {
  const [Probability, setProbability] = useState(28);
  const [selected21, setSelected21] = useState('Adverse Events');
  const [selected22, setSelected22] = useState("Nonlinear");
  const buttonData21 = [
    'Adverse Events',
    'Predicate Network (age-based)',
    'Predicate Network (recall-based)',
    'Predicate Network (adverse event-based)',
    'Predicate Network Features (other)',
  ];

  const buttonData22 = [
    'Linear',
    'Nonlinear',
    'Ensemble',
    'Advanced'
  ];

  

  const adverseEventsFeatures = [
    { id: 'Number of deaths', content: 'Number of deaths' },
    { id: 'Number of injuries', content: 'Number of injuries' },
    { id: 'Number of malfunctions', content: 'Number of malfunctions' },
    { id: 'Number of other events', content: 'Number of other events' }
  ];

  const predicateNetworkAgeBasedFeatures = [
    { id: 'Approval date difference (max)', content: 'Approval date difference between predicates and applicant (max)' },
    { id: 'Approval date difference (min)', content: 'Approval date difference between predicates and applicant (min)' },
    { id: 'Approval date difference (std)', content: 'Approval date difference between predicates and applicant (std)' },
    { id: 'Approval date difference (mean)', content: 'Approval date difference between predicates and applicant (mean)' },
    { id: 'Approval date difference (median)', content: 'Approval date difference between predicates and applicant (median)' }
  ];
  
  const predicateNetworkRecallBasedFeatures = [
    { id: 'Number of recalls among predicates', content: 'Number of recalls among predicates' },
    { id: 'Percentage of recalls among predicates', content: 'Percentage of recalls among predicates' },
    { id: 'Number of ongoing recalls among predicates', content: 'Number of ongoing recalls among predicates' },
    { id: 'Percentage of ongoing recalls among predicates', content: 'Percentage of ongoing recalls among predicates' }
  ];
  const predicateNetworkAdverseEventBasedFeatures = [
    { id: 'Number of deaths among predicates', content: 'Number of deaths among predicates' },
    { id: 'Percentage of predicates with at least one death', content: 'Percentage of predicates with at least one death' },
    { id: 'Number of injuries among predicates', content: 'Number of injuries among predicates' },
    { id: 'Percentage of predicates with at least one injury', content: 'Percentage of predicates with at least one injury' },
    { id: 'Number of malfunctions among predicates', content: 'Number of malfunctions among predicates' },
    { id: 'Percentage of predicates with at least one malfunction', content: 'Percentage of predicates with at least one malfunction' },
    { id: 'Number of other adverse events among predicates', content: 'Number of other adverse events among predicates' },
    { id: 'Percentage of predicates with at least one other adverse event', content: 'Percentage of predicates with at least one other adverse event' }
  ];
  

  const otherFeatures = [
    { id: 'Unique number of product codes', content: 'Unique number of product codes among predicates' },
    { id: 'Entropy of product codes', content: 'Entropy of product codes among predicates' },
    { id: 'Unique number of medical specialties', content: 'Unique number of medical specialties among predicates' },
    { id: 'Entropy of medical specialties', content: 'Entropy of medical specialties among predicates' },
    { id: 'Unique number of applicant companies', content: 'Unique number of applicant companies among predicates' },
    { id: 'Entropy of applicant companies', content: 'Entropy of applicant companies among predicates' },
    { id: 'Number of predicates with a different product code than the applicant', content: 'Number of predicates with a different product code than the applicant' },
    { id: 'Percentage of predicates with a different product code than the applicant', content: 'Percentage of predicates with a different product code than the applicant' },
    { id: 'Number of predicates with a different medical specialty than the applicant', content: 'Number of predicates with a different medical specialty than the applicant' },
    { id: 'Percentage of predicates with a different medical specialty than the applicant', content: 'Percentage of predicates with a different medical specialty than the applicant' },
    { id: 'Number of predicates with a different applicant company than the applicant', content: 'Number of predicates with a different applicant company than the applicant' },
    { id: 'Percentage of predicates with a different applicant company than the applicant', content: 'Percentage of predicates with a different applicant company than the applicant' }
  ];
  const [leftItems, setLeftItems] = useState(adverseEventsFeatures);

  const [leftMidItems, setLeftMidItems] = useState([
  ]);
  const [rightMidItems, setRightMidItems] = useState([
    // 更多项...
  ]);
  

  const NonLinearModels = [
    { id: 'KNN', content: 'K Nearest Neighbors' },
    { id: 'SVM', content: 'Support Vector Machine' },
    { id: 'DT', content: 'Decision Tree' },
    { id: 'MLP', content: 'Multi-layer Perceptron' },
  ];
  
  const LinearModels = [
    { id: 'LR', content: 'Logistic Regression' },
    { id: 'Ridge', content: 'Ridge Regression' },
    { id: 'Lasso', content: 'Lasso Regression' },
    { id: 'LDA', content: 'Linear Discriminant Analysis' },
    { id: 'NB', content: 'Naïve Bayes' },
  ];
  
  const EnsembleModels = [
    { id: 'RF', content: 'Random Forest' },
    { id: 'Bagging', content: 'Bagging' },
    { id: 'AdaBoost', content: 'Ada Boost' },
    { id: 'GB', content: 'Gradient Boosting' },
    { id: 'XGBoost', content: 'Extreme Gradient Boosting' },
  ];
  
  const AdvancedModels = [
    { id: 'DeepPredicate', content: 'DeepPredicate' },
    { id: 'Transformer', content: 'Transformer' },
  ];
  const [rightItems, setRightItems] = useState(NonLinearModels);
  const [predictionWindow, setPredictionWindow] = useState(4);

  const predictionWindowChange = (value: number) => {
    setPredictionWindow(value);
    //randomSetProbability();
  }
  const handleButtonClick21 = (value: string) => {
    if (value !== selected21) {
      setSelected21(value);
      let selectedFeatures = [];
  switch (value) {
    case 'Adverse Events':
      selectedFeatures = [...adverseEventsFeatures];
      break;
    case 'Predicate Network (age-based)':
      selectedFeatures = [...predicateNetworkAgeBasedFeatures];
      break;
    case 'Predicate Network (recall-based)':
      selectedFeatures = [...predicateNetworkRecallBasedFeatures];
      break;
    case 'Predicate Network (adverse event-based)':
      selectedFeatures = [...predicateNetworkAdverseEventBasedFeatures];
      break;
    case 'Predicate Network Features (other)':
      selectedFeatures = [...otherFeatures];
      break;
    default:
      // 可以处理默认情况或保持不变
      break;
  }

  setLeftItems(selectedFeatures);

  // 从 selectedFeatures 中随机选择三个元素
  if (selectedFeatures.length > 0) {
    let selectedMidItems = [];
    for (let i = 0; i < 3; i++) {
      if (selectedFeatures.length === 0) {
        break; // 如果特征不足三个，则提前终止循环
      }
      const randomIndex = Math.floor(Math.random() * selectedFeatures.length);
      selectedMidItems.push(selectedFeatures[randomIndex]);
      selectedFeatures.splice(randomIndex, 1); // 从数组中移除已选中的元素
    }
    setLeftMidItems(selectedMidItems);
  }
    }

  };

  const handleButtonClick22 = (value: string) => {
    if (value !== selected22) {
      setSelected22(value);
  
      let selectedModels = [];
      switch (value) {
        case 'Linear':
          selectedModels = [...LinearModels]; // 创建数组的副本
          break;
        case 'Nonlinear':
          selectedModels = [...NonLinearModels]; // 创建数组的副本
          break;
        case 'Ensemble':
          selectedModels = [...EnsembleModels]; // 创建数组的副本
          break;
        case 'Advanced':
          selectedModels = [...AdvancedModels]; // 创建数组的副本
          break;
        default:
          // 可以设置默认的 rightItems，或者保持不变
          break;
      }
  
      if (selectedModels.length > 0) {
        const randomIndex = Math.floor(Math.random() * selectedModels.length);
        const randomModel = selectedModels[randomIndex];
  
        // 从数组中移除选中的元素
        selectedModels.splice(randomIndex, 1);
  
        setRightMidItems([randomModel]); // 设置选中的元素到 midRightItem
      }
  
      setRightItems(selectedModels); // 更新 rightItems，不包括被选中的元素
    }
  };
  
  useEffect(() => {
    
    if (leftItems.length > 0) {
      let selectedMidItems = [];
      for (let i = 0; i < 3; i++) {
        if (leftItems.length === 0) {
          break; // 如果特征不足三个，则提前终止循环
        }
        const randomIndex = Math.floor(Math.random() * leftItems.length);
        selectedMidItems.push(leftItems[randomIndex]);
        leftItems.splice(randomIndex, 1); // 从数组中移除已选中的元素
      }
      setLeftMidItems(selectedMidItems);
    }
    setRightMidItems([rightItems[0]]);
    const changedItems = rightItems.splice(0, 1);
    
  }
  , []);

  const onDragEnd = (result) => {
    const { source, destination } = result;
  
    // 如果目标为空，不做任何操作
    if (!destination) {
      return;
    }
  
    // 源和目标相同，不做任何操作
    if (source.droppableId === destination.droppableId &&
        source.index === destination.index) {
      return;
    }
  
    // 获取源和目标列表
    const sourceList = getSourceList(source.droppableId);
    const destinationList = getSourceList(destination.droppableId);
  
    // 移除源列表中的元素
    const [removed] = sourceList.splice(source.index, 1);
  
    // 检查是否为有效的拖拽操作
    if (isValidDrop(source.droppableId, destination.droppableId)) {
      if (destination.droppableId === 'rightMidDrop' && destinationList.length) {
        // 交换情况
        const [existing] = destinationList.splice(0, 1);
        sourceList.splice(source.index, 0, existing); // 将原有的元素放回源列表
      }
      // 正常放置情况
      destinationList.splice(destination.index, 0, removed);
  
      // 更新状态
      updateState(source.droppableId, sourceList);
      updateState(destination.droppableId, destinationList);
    } else {
      // 放置位置错误
      message.error('Invalid drop location');
    }
  };
  
  
  const getSourceList = (droppableId) => {
    switch (droppableId) {
      case 'leftDrop': return [...leftItems];
      case 'leftMidDrop': return [...leftMidItems];
      case 'rightMidDrop': return [...rightMidItems];
      case 'rightDrop': return [...rightItems];
      default: return [];
    }
  };
  
  // 验证是否为有效的拖拽操作
  const isValidDrop = (sourceId, destinationId) => {
    const validPairs = [
      ['leftDrop', 'leftMidDrop'],
      ['leftMidDrop', 'leftDrop'],
      ['rightMidDrop', 'rightDrop'],
      ['rightDrop', 'rightMidDrop']
    ];
    return validPairs.some(pair => pair[0] === sourceId && pair[1] === destinationId);
  };
  
  // 更新状态
  const updateState = (droppableId, newList) => {
    switch (droppableId) {
      case 'leftDrop': setLeftItems(newList); break;
      case 'leftMidDrop': setLeftMidItems(newList); break;
      case 'rightMidDrop': setRightMidItems(newList); break;
      case 'rightDrop': setRightItems(newList); break;
      default: break;
    }
  };

  const SvgArrow = () => (
    <svg width="25" height="25" viewBox="0 0 50 50">
      <path d="M0 25 L50 25 M25 0 L50 25 L25 50" stroke="black" strokeWidth="2" fill="none" />
    </svg>
  );
  

  const randomSetProbability = () => {
    setProbability(Math.round(Math.random() * 100));
  }










  
    return (
        <div className={styles.contentContainer}>
          <p className={styles.CardsTitle}> Predicted Recall Probability </p> 
          <div className={styles.cardContainer1}>
            <Progress  type="circle" percent={Probability} status="active" strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }} />
          </div>
        {/* ... 
          {isContainerReady && <DragMap />}
        */}    

        
          <DragDropContext onDragEnd={onDragEnd}>
            <div className={styles.cardContainer2}>
              <div className={styles.subContainer21}>
                <div className={styles.subCard1}>
                  <p className={styles.subTitle21}> Select Features </p> 
                  <DraggableContainer 
                    items={leftItems} 
                    droppableId="leftDrop"
                    className={styles.leftItem}
                  />
                </div>
                <div className={styles.buttonGroup1}>
                  {buttonData21.map(btn => (
                    <button
                      className={`${styles.btn} ${selected21 === btn ? styles.active : ''}`}
                      onClick={() => handleButtonClick21(btn)}
                    >
                      {btn}
                    </button>
                  
                  ))}
                </div>
              </div>
              <div className={styles.subContainer22}>
                <p className={styles.CardsTitle}> Model Process </p> 
                <div className={styles.midDropContainer}>
                  <div style={{width:'60%'}}>
                    <DraggableContainer 
                      items={leftMidItems} 
                      droppableId="leftMidDrop"
                      className={styles.leftMidItem}
                    />
                  </div>
                  
                  <SvgArrow />

                  <div className={styles.midRightContainer}>
                    <DraggableContainer 
                      items={rightMidItems} 
                      droppableId="rightMidDrop"
                      className={styles.rightMidItem}
                    />
                  </div>
                  
                </div>
                
                <div className={styles.selectContainer}>
                  <p className={styles.subTitle22}> Select Prediction Window </p> 
                  <Select
                        defaultValue={`${predictionWindow} ${predictionWindow > 1 ? 'years' : 'year'}`}
                        style={{ width: '100%' }}
                        onChange={(value) => predictionWindowChange(Number(value))}
                        options={[
                          { value: 1, label: '1 year' },
                          { value: 2, label: '2 years' },
                          { value: 3, label: '3 years' },
                          { value: 4, label: '4 years' },
                        ]}
                      />
                </div>
                
              </div>
              <div className={styles.subContainer23}>
                <div className={styles.subCard2}>
                  <p className={styles.subTitle23}> Select Models </p> 
                  <DraggableContainer 
                    items={rightItems} 
                    droppableId="rightDrop"
                    className={styles.rightItem}
                  />
                </div>
                <div className={styles.buttonGroup2}>
                    {buttonData22.map(btn => (
                      <button
                        className={`${styles.btn} ${selected22 === btn ? styles.active : ''}`}
                        onClick={() => handleButtonClick22(btn)}
                      >
                        {btn}
                      </button>
                    
                    ))}
                </div>
              </div>
            </div>
          </DragDropContext>

          <button  className={styles.roundedButton} onClick={randomSetProbability}>Predict Recall </button>
        </div>
    );
}

export default observer(CustomModel);
