
import { Graph, Node } from '@antv/x6';
import { register } from '@antv/x6-react-shape';
import { Dropdown } from 'antd';
import styles from './index.module.scss';
import { observer } from 'mobx-react'; 
import React, { useState, useEffect } from 'react';
import { click } from '@testing-library/user-event/dist/click';
const NodeComponent = ({ node, graph, className }: { node: Node, graph: Graph, className: string }) => {
  const label = node.prop('label');
  return (
    <Dropdown
      menu={{
        items: [
          { key: 'copy', label: 'copy' },
          { key: 'paste', label: 'paste' },
          { key: 'delete', label: 'delete' },
        ],
      }}
      trigger={['contextMenu']}
    >
      <div className={className}>{label}</div>
    </Dropdown>
  );
};


register({
  shape: 'subnode',
  width: 100,
  height: 40,
  component: ({ node, graph }) => <NodeComponent node={node} graph={graph} className={styles.subNode} />,
});

register({
  shape: 'darkRedNode',
  width: 100,
  height: 40,
  component: ({ node, graph }) => <NodeComponent node={node} graph={graph} className={styles.lightRedNode} />,
});

register({
  shape: 'darkRedNode',
  width: 100,
  height: 40,
  component: ({ node, graph }) => <NodeComponent node={node} graph={graph} className={styles.darkRedNode} />,
});

register({
  shape: 'mainNode',
  width: 100,
  height: 100,
  component: ({ node, graph }) => <NodeComponent node={node} graph={graph} className={styles.mainNode} />,
});

register({
  shape: 'peerNode',
  width: 100,
  height: 100,
  component: ({ node, graph }) => <NodeComponent node={node} graph={graph} className={styles.peerNode} />,
});



const data = {
    nodes: [
      { id: 'K000001', shape: 'mainNode', x: 0, y: -30, label: 'Focal\nDevice\nK000001' },
      { id: 'Peer1', shape: 'peerNode', x: 0, y: 100, label: 'Peer 1' },
      { id: 'Peer2', shape: 'peerNode', x: 0, y: -150, label: 'Peer 2' },
      { id: 'K000002', shape: 'darkRedNode', x: 200, y: 100, label: 'K000002' },
      { id: 'K000010', shape: 'darkRedNode', x: 200, y: 200, label: 'K000010' },
      { id: 'K000011', shape: 'darkRedNode', x: 200, y: 0, label: 'K000011' },
      { id: 'K000004', shape: 'darkRedNode', x: 200, y: -100, label: 'K000004' },
      { id: 'K000005', shape: 'darkRedNode', x: 200, y: -200, label: 'K000005' },
      { id: 'K000003', shape: 'darkRedNode', x: 400, y: 300, label: 'K000003' },
      { id: 'K000006', shape: 'darkRedNode', x: 400, y: 200, label: 'K000006' },
      { id: 'K000009', shape: 'darkRedNode', x: 400, y: 100, label: 'K000009' },
      { id: 'K000012', shape: 'darkRedNode', x: 400, y: 0, label: 'K000012' },
      { id: 'K000013', shape: 'darkRedNode', x: 400, y: -100, label: 'K000013' },
      { id: 'K000014', shape: 'darkRedNode', x: 400, y: -200, label: 'K000014' },
      { id: 'K000015', shape: 'darkRedNode', x: 400, y: -300, label: 'K000015' },
      

      { id: 'K000016', shape: 'subnode', x: -200, y: 100, label: 'K000016' },
      { id: 'K000017', shape: 'subnode', x: -200, y: 200, label: 'K000017' },
      { id: 'K000018', shape: 'subnode', x: -200, y: 0, label: 'K000018' },
      { id: 'K000019', shape: 'subnode', x: -200, y: -100, label: 'K000019' },
      { id: 'K000020', shape: 'subnode', x: -200, y: -200, label: 'K000020' },
      { id: 'K000021', shape: 'subnode', x: -400, y: 300, label: 'K000021' },
      { id: 'K000022', shape: 'subnode', x: -400, y: 200, label: 'K000022' },
      { id: 'K000023', shape: 'subnode', x: -400, y: 100, label: 'K000023' },
      { id: 'K000024', shape: 'subnode', x: -400, y: 0, label: 'K000024' },
      { id: 'K000025', shape: 'subnode', x: -400, y: -100, label: 'K000025' },
      { id: 'K000026', shape: 'subnode', x: -400, y: -200, label: 'K000026' },
      { id: 'K000027', shape: 'subnode', x: -400, y: -300, label: 'K000027' },
    ],
    edges: [
   
      { source: 'Peer1', target: 'K000004', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000004', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000005', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'K000001', target: 'K000002', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'K000001', target: 'K000004', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'K000001', target: 'K000005', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'K000001', target: 'K000010', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'K000001', target: 'K000011', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000006', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000009', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000004', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000013', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000014', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000015', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },

      { source: 'Peer1', target: 'K000016', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000019', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000017', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000018', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000017', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000018', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000019', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000020', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer1', target: 'K000021', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000022', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000023', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000024', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000025', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000026', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      { source: 'Peer2', target: 'K000027', attrs: { line: { stroke: '#8f8f8f', strokeWidth: 1 } } },
      // ... 添加其他边 ...
    ],
  }
  
  
  class NodeMap extends React.Component {
    
    private container: HTMLDivElement;
    componentDidMount() {
      const graph = new Graph({
        container: this.container,
        background: { color: '#ffffff' },
        autoResize: true,
        panning: true,
        mousewheel: true,
      });
  
      graph.fromJSON(data);
      graph.zoomToFit({ maxScale: 1 })
      graph.centerContent();
      graph.zoom(-0.05);
      console.log('graph');
    }
  
    refContainer = (container: HTMLDivElement) => {
      this.container = container;
    };
  
    render() {
      return (
        <div className={styles.reactShapeApp}>
          <div className = {styles.appContent} ref={ref => this.container = ref} />
        </div>
      );
    }
  }
  export default observer(NodeMap);