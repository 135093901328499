import React from 'react';
import styles from './index.module.scss';
import { Progress, Space } from 'antd';
import { LineChart, CustomTable} from '@/components';
type RiskLevel = 'Low' | 'Medium' | 'High';

const RecallPrediction: React.FC = () => {
    const currentRisk: RiskLevel = 'High';

    const headers = ["Time Window", "Accuracy Score", "F1 Score"];
    const card22Data = [
        { timeWindow: 'Within 1 year', ACC: '78%', F1: '39%' },
        { timeWindow: 'Within 2 years', ACC: '81%', F1: '48%' },
        { timeWindow: 'Within 3 years', ACC: '84%', F1: '56%' },
        { timeWindow: 'Within 4 years', ACC: '88%', F1: '64%' },
        { timeWindow: 'Within 5 years', ACC: '93%', F1: '72%' },
    ];
    return (
        <div className={styles.contentContainer}>
            <div className={styles.topContainer}>
                <div className={styles.topSubContainer}>
                    <p className={styles.header}> Recall Probability Level </p>
                    <div className={styles.riskCard}>
                        <div className={`${styles.highLevel} ${(currentRisk as string) !== 'High' ? styles.disabled : styles.high}`}>
                            High
                        </div>
                    </div>
                </div>
                <div className={styles.topSubContainer}>
                    <p className={styles.header}> Recall Probability </p>
                    <div className={styles.probabilityCard}>
                        <p className={styles.subHeader}> Within Next 1 Year </p>
                        <Progress percent={24} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />
                    </div>
                    <div className={styles.probabilityCard2}>
                        <p className={styles.subHeader}> Within Next 2 Years </p>
                        <Progress percent={28} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />
                    </div>
                </div>
                <div className={styles.topSubContainer}>
                    <p className={styles.header}> Number of Recalled Devices </p>
                    <div className={styles.probabilityCard}>
                        <p className={styles.subHeader2}> Among Predicates </p>
                        <p className={styles.contentText}> {3} </p>
                    </div>
                    <div className={styles.probabilityCard2}>
                        <p className={styles.subHeader2}> Among Predicate Network </p>
                        <p className={styles.contentText}> {6} </p>
                    </div>
                </div>
            </div>
            <div className={styles.buttomContainer}>


                    <div className={styles.buttomCard1}>
                        <p className={styles.buttomHeader}> Recall Probability Prediction </p>
                        <LineChart />
                    </div>
                    <div className={styles.buttomCard}>
                        <p className={styles.buttomHeader}> Prediction Reliability </p>
                        <CustomTable headers={headers} data={card22Data} />
                    </div>
            </div>
        </div>
    );
}

export default RecallPrediction;

